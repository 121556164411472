
  import { Component, Mixins } from "vue-property-decorator";
  import { Action } from "vuex-class";
  import { bus } from "@/main";
  import { HasNotary } from "@/mixins/has-notary";
  import { HasSellers } from "@/mixins/has-sellers";
  import { HasSellersWithRelation } from "@/mixins/has-sellers-with-relation";
  import { HasRenters } from "@/mixins/activities/contacts/has-renters";
  import { ActivitySeller, ContactType } from "@/store/modules/activity-seller.store";

  @Component({
    components: {
      SellerInfo: () => import("@/components/seller/seller-info.vue"),
      RenterInfo: () => import("@/components/dossier/contacts/renter-info.vue"),
      activitySellerDeleteModal: () => import("@/components/modals/delete-activity-seller.vue"),
      sellerUpdateModal: () => import("@/components/modals/update-seller.vue"),
      sellerReinviteModal: () => import("@/components/modals/reinvite-seller.vue"),
      NotaryInfo: () => import("@/components/dossier/contacts/notary-info.vue"),
    },
  })
  export default class DossierPartSeller extends Mixins(HasSellers, HasSellersWithRelation, HasNotary, HasRenters) {
    @Action("bid/index") indexBids!: BidIndex;

    loading = false;

    bus = bus;

    get sellersWithRelationExcludingPotentialBuyers() {
      return this.sellersWithRelation.filter((seller) => seller.type !== ContactType.potentialBuyer);
    }

    async mounted() {
      this.loading = true;

      await this.getSellers();

      await this.indexBids({ activity_id: this.activity.id });

      this.loading = false;
    }
  }
