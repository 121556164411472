import { Mandate } from "@/store/modules/mandate.store";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";

interface Renter {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

@Component
export class HasRenters extends Vue {
  @Getter("mandate/viewing") mandate!: Mandate | undefined;

  /**
   * @name mandate renters
   * @description Return the renters in the active mandate
   *
   * return {Array} renters
   */
  get renters(): Renter[] {
    return this.removeDuplicates([...this.noKeyAvailableRenters, ...this.propertyOccupiedRenters]);
  }

  get mandateDetails() {
    return this.mandate?.details;
  }

  /**
   * @name mandate propertyOccupiedRenters
   * @description Return the renters in the active mandate that are assigned when the property is occupied
   *
   * return {Array} renters
   */
  get propertyOccupiedRenters(): Renter[] {
    if (
      !this.mandateDetails ||
      this.mandateDetails.article_2_is_property_occupied_occupant_type === null ||
      !this.mandateDetails.article_2_is_property_occupied_occupant_type ||
      this.mandateDetails.article_2_is_property_occupied_occupant_type !== "renter"
    ) {
      return [];
    }

    return [
      {
        first_name: this.mandateDetails.article_2_is_property_occupied_occupant_first_name ?? "",
        last_name: this.mandateDetails.article_2_is_property_occupied_occupant_last_name ?? "",
        email: this.mandateDetails.article_2_is_property_occupied_occupant_email ?? "",
        phone: this.mandateDetails.article_2_is_property_occupied_occupant_phone ?? "",
      },
    ];
  }

  /**
   * @name mandate noKeyAvailableRenters
   * @description Return the renters in the active mandate that are assigned when no key is available
   *
   * return {Renter[]} renters
   */
  get noKeyAvailableRenters(): Renter[] {
    if (
      !this.mandateDetails ||
      this.mandateDetails.article_2_keys_available_holder_type === null ||
      !this.mandateDetails.article_2_keys_available_holder_type ||
      this.mandateDetails.article_2_keys_available_holder_type !== "renter"
    ) {
      return [];
    }

    return [
      {
        first_name: this.mandateDetails.article_2_keys_available_holder_first_name ?? "",
        last_name: this.mandateDetails.article_2_keys_available_holder_last_name ?? "",
        email: this.mandateDetails.article_2_keys_available_holder_email ?? "",
        phone: this.mandateDetails.article_2_keys_available_holder_phone ?? "",
      },
    ];
  }

  areObjectsEqual(obj1: Renter, obj2: Renter): boolean {
    return (
      obj1.first_name.toLowerCase() === obj2.first_name.toLowerCase() &&
      obj1.last_name.toLowerCase() === obj2.last_name.toLowerCase() &&
      obj1.email.toLowerCase() === obj2.email.toLowerCase() &&
      obj1.phone.toLowerCase() === obj2.phone.toLowerCase()
    );
  }

  removeDuplicates(renter: Renter[]): Renter[] {
    return renter.filter((renter, index, self) => {
      return index === self.findIndex((obj) => this.areObjectsEqual(obj, renter));
    });
  }
}
